import HomeMastHead from '../../components/Home/HomeMastHead';
import HomeSectionResources from '../../components/Home/HomeSectionResources';

function Home() {

  return (
      <>
        <HomeMastHead />
        <HomeSectionResources />
      </>
  );
}

export default Home;